// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-cipher-js": () => import("./../src/components/Cipher.js" /* webpackChunkName: "component---src-components-cipher-js" */),
  "component---src-components-ciphers-list-js": () => import("./../src/components/CiphersList.js" /* webpackChunkName: "component---src-components-ciphers-list-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-diskuse-js": () => import("./../src/pages/diskuse.js" /* webpackChunkName: "component---src-pages-diskuse-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-hre-js": () => import("./../src/pages/o-hre.js" /* webpackChunkName: "component---src-pages-o-hre-js" */),
  "component---src-pages-podekovani-js": () => import("./../src/pages/podekovani.js" /* webpackChunkName: "component---src-pages-podekovani-js" */),
  "component---src-pages-pravidla-js": () => import("./../src/pages/pravidla.js" /* webpackChunkName: "component---src-pages-pravidla-js" */),
  "component---src-pages-registrace-js": () => import("./../src/pages/registrace.js" /* webpackChunkName: "component---src-pages-registrace-js" */),
  "component---src-pages-reportaze-js": () => import("./../src/pages/reportaze.js" /* webpackChunkName: "component---src-pages-reportaze-js" */),
  "component---src-pages-tymy-js": () => import("./../src/pages/tymy.js" /* webpackChunkName: "component---src-pages-tymy-js" */),
  "component---src-pages-vas-tym-js": () => import("./../src/pages/vas-tym.js" /* webpackChunkName: "component---src-pages-vas-tym-js" */),
  "component---src-pages-vysledky-js": () => import("./../src/pages/vysledky.js" /* webpackChunkName: "component---src-pages-vysledky-js" */),
  "component---src-pages-zapomenute-heslo-js": () => import("./../src/pages/zapomenute-heslo.js" /* webpackChunkName: "component---src-pages-zapomenute-heslo-js" */)
}

